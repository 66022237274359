import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo';
import Latest from '../templates/latest';

const IndexPage = () => (
    <Layout>
        <SEO title='' description={'Personal web page of developer Piotr Sienkiewicz'} />
        <h1>Hello</h1>
        <h2>My name is Piotr</h2>
        <p>
            Welcome
        </p>
        {/*<h2>Latest Posts</h2>*/}
        {/*<hr/>*/}
        {/*<Latest/>*/}
    </Layout>
)

export default IndexPage
