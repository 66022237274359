import React from 'react'
import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import SmallExcerpt from '../components/smallExcerpt';

const Latest = () => {
    const data = useStaticQuery(graphql`
  query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//posts//" } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 2
        skip: 0
      ) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              title
              date(formatString: "DD MMMM YYYY")
              path
              author
              excerpt
              tags
              coverImage {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const {
    allMarkdownRemark: { edges: posts },
  } = data

  return (
    <>
        {posts.map(({ node }) => {
          const {
            id,
            excerpt: autoExcerpt,
            frontmatter: {
              title,
              date,
              path,
              author,
              coverImage,
              excerpt,
              tags,
            },
          } = node

          return (
            <SmallExcerpt
              key={id}
              title={title}
              date={date}
              path={path}
              author={author}
              coverImage={coverImage}
              tags={tags}
              excerpt={excerpt || autoExcerpt}
            />
          )
        })}
    </>
  )
}

Latest.propTypes = {
  data: PropTypes.object.isRequired,
}



export default Latest
