import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { toKebabCase } from '../helpers'

import style from '../styles/smallpost.module.css'

const smallExcerpt = ({
  title,
  date,
  path,
  coverImage,
  excerpt,
  tags,
  previousPost,
  nextPost,
}) => {

  return (
    <div className={style.post}>
      <div className={style.postContent}>
        <h2 className={style.title}>
          {excerpt ? <Link to={path}>{title}</Link> : title}
        </h2>
        <div className={style.meta}>
          {tags ? (
            <div className={style.tags}>
              {tags.map(tag => (
                <Link to={`/tag/${toKebabCase(tag)}/`} key={toKebabCase(tag)}>
                  <span className={style.tag}>#{tag}</span>
                </Link>
              ))}
              - {date}
            </div>
          ) : null}
        </div>
        <div>
          <div className={style.leftExcerpt} >
            <Img
                fluid={coverImage.childImageSharp.fluid}
                className={style.smallImage}
            />
          </div>
          <div className={style.rightExcerpt} >
            <p>{excerpt}</p>
            <Link to={path} className={style.readMore}>
              Read more →
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

smallExcerpt.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  author: PropTypes.string,
  excerpt: PropTypes.string,
  html: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  previousPost: PropTypes.object,
  nextPost: PropTypes.object,
}

export default smallExcerpt
